<template>
  <div>
    <div class="cont_header top-0 h-20 fixed flex flex-row w-full justify-end items-center align-middle bg-white shadow-[0rem_0rem_1rem_-0.5rem_#000000] font-semibold">
      <div class="cont_LogoJB absolute left-0 hidden lg:flex flex-col justify-evenly items-center align-middle w-64 p-2">
        <img src="/img/JB/JB_logo.png" alt="">
      </div>
      <div class="cont_LogoJB absolute left-0 flex lg:hidden flex-col justify-evenly items-center align-middle w-20 p-2 mx-2">
        <img src="/img/JB/Logo_JB.png" alt="">
      </div>
      <div class="cont_DatosCliente relative w-11/12 lg:w-4/5 text-left ml-24">
        <ul class="flex flex-row justify-evenly items-center align-middle w-full">
          <li class="flex flex-row justify-center items-center align-middle mr-4">
            <div class="inline-flex flex-col justify-center items-center align-middle p-2 border border-[#f93a5a] rounded-lg md:rounded-2xl w-6 h-6 md:w-10 md:h-10 mr-2 md:mr-4 bg-[#f93a5a]">
              <i class="fa-solid fa-location-dot text-white scale-90 md:scale-125"></i>
            </div>
            <div class="inline-block">
              <span>{{ infoCliente.idUbicacionGeograficaNavigation[0].idZonaNavigation.codigoZona }}</span>
            </div>
          </li>
          <li class="flex flex-row justify-center items-center align-middle mr-4">
            <div class="inline-flex flex-col justify-center items-center align-middle p-2 border border-[#f93a5a] rounded-lg md:rounded-2xl w-6 h-6 md:w-10 md:h-10 mr-2 md:mr-4 bg-[#f93a5a]">
              <i class="fa-solid fa-address-card text-white scale-90 md:scale-125"></i>
            </div>
            <div class="inline-block">
              <span>{{ infoCliente.numdocumento }}</span>
            </div>
          </li>
          <li class="flex flex-row justify-center items-center align-middle mr-4">
            <div class="inline-flex flex-col justify-center items-center align-middle p-2 border border-[#f93a5a] rounded-lg md:rounded-2xl w-6 h-6 md:w-10 md:h-10 mr-2 md:mr-4 bg-[#f93a5a]">
              <i class="fa-solid fa-user text-white scale-90 md:scale-125"></i>
            </div>
            <div class="inline-block text-xs sm:text-sm lg:text-base">
              <span>{{ infoCliente.primerNombre + " " + infoCliente.segundoNombre + " " + infoCliente.primerApellido + " " + infoCliente.segundoApellido }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default{
  name: "Header",
  setup(){
    const store = useStore()

    const infoCliente = computed(() => store.state.infoCliente)

    return{
      infoCliente
    }
  }
}
</script>

<style>

</style>