<template>
  <div class="cont_ModalCargando z-50" v-if="Cargando">
    <ModalCargando />
  </div>
  <div class=" absolute" v-if="MostrarHeader">
    <Header />
  </div>
  <router-view/>
</template>

<script>
import Header from '@/components/Header.vue'
import ModalCargando from '@/components/ModalCargando.vue'
import { computed } from 'vue';
import { useStore } from 'vuex';

export default{
  name: "app",
  components: {
    ModalCargando,
    Header
  },
  setup(){
    const store = useStore()

    const Cargando = computed(() => store.state.Cargando)

    const SesionActiva = computed(() => store.state.SesionActiva)

    const MostrarHeader = computed(() => store.state.MostrarHeader)

    return{
      Cargando,
      SesionActiva,
      MostrarHeader
    }
  }
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
*{
  -webkit-tap-highlight-color: #f93a5a;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
button:focus,
input:focus,
textarea:focus,
select:focus{
  outline: none;
}
::-moz-selection {
  color: #fff;
  background: #f93a5a;
}

::selection {
  color: #fff;
  background: #f93a5a;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: block;
  /*
  width: 100vh;
  */
  height: 100vh;
}
.cont_ModalCargando.z-50 {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #000000cc;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
</style>
