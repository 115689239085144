import { createStore } from 'vuex'

export default createStore({
  state: {
    produccion: true,
    urlApi: "",
    token: "",
    SesionActiva: false,
    Cargando: false,
    MostrarHeader: false,
    infoCliente: {},

    EmailConfirmado: false,
    CodigoConfirmado: false,
    CanalesConfirmados: false,

    llamadaYWhatsApp: true,
    llamadaYSMS: false,
    llamadaYEMail: false,

    RecuperarContrasena: false,
    CedulaRecuperar: 0,
    CedulaConfirmada: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
